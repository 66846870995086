"use client";

import { BackButton } from "@/components/back-button";
import {
  Button,
  Container,
  HStack,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import Link from "next/link";

export default function NotFound() {
  return (
    <Container py="24">
      <Stack spacing="10">
        <Stack
          spacing={{ base: "4", md: "6" }}
          align="center"
          textAlign="center"
        >
          <Stack spacing="3">
            <Text
              fontSize={{ base: "sm", md: "md" }}
              fontWeight="medium"
              color="accent"
            >
              Error 404
            </Text>
            <Heading size={{ base: "md", md: "lg" }} fontWeight="semibold">
              Page not found
            </Heading>
          </Stack>
          <Text color="fg.muted" fontSize={{ base: "lg", md: "xl" }} maxW="3xl">
            {
              "Sorry, the page you are looking for doesn't exist or has been moved."
            }
          </Text>
        </Stack>
        <HStack spacing="3" justify="center">
          <BackButton size="xl" variant="secondary" color="secondary">
            Go back
          </BackButton>
          <Button size="xl" as={Link} href="/">
            Take me home
          </Button>
        </HStack>
      </Stack>
    </Container>
  );
}
