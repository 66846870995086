"use client";

import { Button, type ButtonProps } from "@chakra-ui/react";
import { useRouter } from "next/navigation";
import { usePageProgress } from "./page-progress";

export type BackButtonProps = Omit<ButtonProps, "onClick">;

export function BackButton({ children, ...props }: BackButtonProps) {
  const router = useRouter();
  const { navigate } = usePageProgress();
  return (
    <Button
      variant="secondary"
      {...props}
      onClick={() => {
        navigate();
        router.back();
      }}
    >
      {children ?? "Go Back"}
    </Button>
  );
}
